import React from 'react'
import Draggable from 'react-draggable'

export class ChessBoard extends React.Component {
  state = {
    activeDrags: 0,
    deltaPosition: {
      x: 0, y: 0
    },
    controlledPosition: {
      x: -400, y: 200
    }
  };

  handleDrag = (e, ui) => {
    const {x, y} = this.state.deltaPosition;
    this.setState({
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  };

  onStart = () => {
    this.setState({activeDrags: ++this.state.activeDrags});
  };

  onStop = () => {
    this.setState({activeDrags: --this.state.activeDrags});
  };
  onDrop = (e) => {
    this.setState({activeDrags: --this.state.activeDrags});
    if (e.target.classList.contains("drop-target")) {
      alert("Dropped!");
      e.target.classList.remove('hovered');
    }
  };
  onDropAreaMouseEnter = (e) => {
    if (this.state.activeDrags) {
      e.target.classList.add('hovered');
    }
  }
  onDropAreaMouseLeave = (e) => {
    e.target.classList.remove('hovered');
  }

  // For controlled component
  adjustXPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {x, y} = this.state.controlledPosition;
    const {x: dx} = this.state.deltaPosition;
    this.setState({controlledPosition: {x: x - dx, y}});
  };

  adjustYPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {x, y} = this.state.controlledPosition;
    const {y: dy} = this.state.deltaPosition;
    this.setState({controlledPosition: {x, y: y - dy}});
  };

  adjustPos = (e, position) => {
    e.preventDefault();
    e.stopPropagation();
    const {x, y} = position;
    const {x:dx, y:dy} = this.state.controlledPosition;
    this.setState({deltaPosition: {x: x - dx, y: y - dy}});
    // this.setState({controlledPosition: {x: x, y: y}});
  };


  render() {
    const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
    const {deltaPosition, controlledPosition} = this.state;
    return(

    <div style={{zIndex:4}}>
    <Draggable defaultPosition = {controlledPosition} {...dragHandlers} onDrag={this.adjustPos}>
    <div>
    <div className='chessboard' style={{position: 'absolute', top: '300px', left: '500px', zIndex: 4, overflow: 'hidden'}}>
    </div>
    <div className='pieceholder' style={{position: 'absolute', top: '635px', left: '500px', zIndex: 4, overflow: 'hidden'}}>
    </div>
    </div>
    </Draggable>

    <Draggable  bounds={{top: controlledPosition.y-0, left: controlledPosition.x, right: controlledPosition.x+280, bottom: controlledPosition.y+280+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_rdt45.svg' style={{position: 'absolute', top: '315px', left: '515px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-40, left: controlledPosition.x, right: controlledPosition.x+280, bottom: controlledPosition.y+240+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_ndt45.svg' style={{position: 'absolute', top: '355px', left: '515px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-80, left: controlledPosition.x, right: controlledPosition.x+280, bottom: controlledPosition.y+200+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_bdt45.svg' style={{position: 'absolute', top: '395px', left: '515px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-120, left: controlledPosition.x, right: controlledPosition.x+280, bottom: controlledPosition.y+160+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_kdt45.svg' style={{position: 'absolute', top: '435px', left: '515px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-160, left: controlledPosition.x, right: controlledPosition.x+280, bottom: controlledPosition.y+120+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_qdt45.svg' style={{position: 'absolute', top: '475px', left: '515px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-200, left: controlledPosition.x, right: controlledPosition.x+280, bottom: controlledPosition.y+80+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_bdt45.svg' style={{position: 'absolute', top: '515px', left: '515px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-240, left: controlledPosition.x, right: controlledPosition.x+280, bottom: controlledPosition.y+40+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_ndt45.svg' style={{position: 'absolute', top: '555px', left: '515px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-280, left: controlledPosition.x, right: controlledPosition.x+280, bottom: controlledPosition.y+0+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_rdt45.svg' style={{position: 'absolute', top: '595px', left: '515px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>




    <Draggable  bounds={{top: controlledPosition.y-0, left: controlledPosition.x-40, right: controlledPosition.x+240, bottom: controlledPosition.y+280+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_pdt45.svg' style={{position: 'absolute', top: '315px', left: '555px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-40, left: controlledPosition.x-40, right: controlledPosition.x+240, bottom: controlledPosition.y+240+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_pdt45.svg' style={{position: 'absolute', top: '355px', left: '555px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-80, left: controlledPosition.x-40, right: controlledPosition.x+240, bottom: controlledPosition.y+200+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_pdt45.svg' style={{position: 'absolute', top: '395px', left: '555px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-120, left: controlledPosition.x-40, right: controlledPosition.x+240, bottom: controlledPosition.y+160+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_pdt45.svg' style={{position: 'absolute', top: '435px', left: '555px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-160, left: controlledPosition.x-40, right: controlledPosition.x+240, bottom: controlledPosition.y+120+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_pdt45.svg' style={{position: 'absolute', top: '475px', left: '555px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-200, left: controlledPosition.x-40, right: controlledPosition.x+240, bottom: controlledPosition.y+80+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_pdt45.svg' style={{position: 'absolute', top: '515px', left: '555px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-240, left: controlledPosition.x-40, right: controlledPosition.x+240, bottom: controlledPosition.y+40+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_pdt45.svg' style={{position: 'absolute', top: '555px', left: '555px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-280, left: controlledPosition.x-40, right: controlledPosition.x+240, bottom: controlledPosition.y+0+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_pdt45.svg' style={{position: 'absolute', top: '595px', left: '555px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>



    <Draggable  bounds={{top: controlledPosition.y-0, left: controlledPosition.x-280, right: controlledPosition.x, bottom: controlledPosition.y+280+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_rlt45.svg' style={{position: 'absolute', top: '315px', left: '795px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-40, left: controlledPosition.x-280, right: controlledPosition.x, bottom: controlledPosition.y+240+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_nlt45.svg' style={{position: 'absolute', top: '355px', left: '795px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-80, left: controlledPosition.x-280, right: controlledPosition.x, bottom: controlledPosition.y+200+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_blt45.svg' style={{position: 'absolute', top: '395px', left: '795px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-120, left: controlledPosition.x-280, right: controlledPosition.x, bottom: controlledPosition.y+160+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_klt45.svg' style={{position: 'absolute', top: '435px', left: '795px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-160, left: controlledPosition.x-280, right: controlledPosition.x, bottom: controlledPosition.y+120+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_qlt45.svg' style={{position: 'absolute', top: '475px', left: '795px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-200, left: controlledPosition.x-280, right: controlledPosition.x, bottom: controlledPosition.y+80+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_blt45.svg' style={{position: 'absolute', top: '515px', left: '795px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-240, left: controlledPosition.x-280, right: controlledPosition.x, bottom: controlledPosition.y+40+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_nlt45.svg' style={{position: 'absolute', top: '555px', left: '795px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-280, left: controlledPosition.x-280, right: controlledPosition.x, bottom: controlledPosition.y+0+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_rlt45.svg' style={{position: 'absolute', top: '595px', left: '795px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>





    <Draggable  bounds={{top: controlledPosition.y-0, left: controlledPosition.x-240, right: controlledPosition.x+40, bottom: controlledPosition.y+280+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_plt45.svg' style={{position: 'absolute', top: '315px', left: '755px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-40, left: controlledPosition.x-240, right: controlledPosition.x+40, bottom: controlledPosition.y+240+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_plt45.svg' style={{position: 'absolute', top: '355px', left: '755px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-80, left: controlledPosition.x-240, right: controlledPosition.x+40, bottom: controlledPosition.y+200+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_plt45.svg' style={{position: 'absolute', top: '395px', left: '755px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-120, left: controlledPosition.x-240, right: controlledPosition.x+40, bottom: controlledPosition.y+160+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_plt45.svg' style={{position: 'absolute', top: '435px', left: '755px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-160, left: controlledPosition.x-240, right: controlledPosition.x+40, bottom: controlledPosition.y+120+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_plt45.svg' style={{position: 'absolute', top: '475px', left: '755px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-200, left: controlledPosition.x-240, right: controlledPosition.x+40, bottom: controlledPosition.y+80+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_plt45.svg' style={{position: 'absolute', top: '515px', left: '755px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-240, left: controlledPosition.x-240, right: controlledPosition.x+40, bottom: controlledPosition.y+40+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_plt45.svg' style={{position: 'absolute', top: '555px', left: '755px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    <Draggable bounds={{top: controlledPosition.y-280, left: controlledPosition.x-240, right: controlledPosition.x+40, bottom: controlledPosition.y+0+95}} defaultPosition = {controlledPosition} positionOffset={deltaPosition} {...dragHandlers} >
    <img draggable="false" src='./pieces/Chess_plt45.svg' style={{position: 'absolute', top: '595px', left: '755px', zIndex: 4, overflow: 'hidden', height:'40px'}}></img>
    </Draggable>

    </div>
    )


  
  }
}

export default ChessBoard