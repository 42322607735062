import React from "react";
import TextTransition, { presets } from "react-text-transition";

const TEXTS = [
  "a membrane scientist.",
  "here for a good time, not a long time.",
  "debugging some code.",
  "making some pasta.",
  "running in snowshoes.",
  "a mountain biker.",
  "an environmental engineer.",
  "querying the database.",
  "observing mass transport.",
  "into fermenting things.",
  "a Longhorn.",
  "using the supercomputer.",
  "a ceramicist.",
  "currently sleeping.",
  "listening to Death Grips.",
  "a computational physicist.",
  "evaluating a hypothesis.",
  "eating a pizza.",
  "a trail runner.",
  "a dog lover.",
  "plotting some data.",
  "at the beach.",
  "built for speed.",
  "a data scientist.",
  "hopefully skiing.",
  "a space cowboy.",
  "in the machine.",
  "a coffee roaster.",
  "running some simulations.",
  "a Tarheel.",
  "right behind you."
]

export const TextSpinner = () => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <h2>
    I am <TextTransition  inline={true} springConfig={presets.stiff}>{TEXTS[index % TEXTS.length]}</TextTransition>
    </h2>
  );
};