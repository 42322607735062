import React from 'react'
import Draggable from 'react-draggable'
import { TextSpinner } from "./TextSpinner.tsx";

const LALIGN = '15px'

export class AboutMe extends React.Component {
  render() {
    return(
    <Draggable>
    <div className='box' id='aboutme' style={{position: 'absolute', top: '20px', left: '20px', zIndex:0, overflow: 'hidden'}}>
    <div className='triangle'>
    </div>
      <h1 unselectable="on" className='name' style={{position: 'absolute', top: '-10px', left: LALIGN}}>
      Riley Vickers, PhD
      </h1>
      <h1 unselectable="on" style={{position: 'absolute', top: '35px', left: LALIGN}}>
      <TextSpinner></TextSpinner>
      </h1>
      <h2 unselectable="on" style={{position: 'absolute', top: '100px', left: LALIGN}}>
        <li unselectable="on"><span>Python, C++, SQL, JS, CSS, HTML</span></li>
        <li unselectable="on"><span>Data engineering and science</span></li>
        <li unselectable="on"><span>High performance computing</span></li>
      </h2>
      <h4 unselectable="on" style={{position: 'absolute', top: '260px', left: LALIGN, width: '390px'}}>
        I love challenging problems that require complex or novel analysis, especially those aimed at improving the sustainability, equity, or efficiency of a given process.
      </h4>
      <img className='img' src='/images/me.png' width={'30%'} style={{position: 'absolute', bottom: '15px', right: '15px'}}/>
    </div>
    </Draggable>
    )
  }
}

export default AboutMe