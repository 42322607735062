import React from 'react'
import Draggable from 'react-draggable'


export class PostIt extends React.Component {
  render() {
    return(
    <Draggable>
    <div>
      <img draggable="false" src='../images/Post-it-note-transparent.png' style={{position: 'absolute', top: '100px', left: '100px', zIndex: 0, height: '200px'}}>
      </img>
      <h1 className='marker' style={{position: 'absolute', top: '120px', left: '140px', fontSize: '18px',  color: '#CC5500'}}>hello</h1>

      <h1 className='marker' style={{position: 'absolute', top: '220px', left: '120px', fontSize: '18px',  color:'#CC5500'}}>you found me!</h1>
      <img draggable="false" src='../images/doodle.svg' style={{position: 'absolute', top: '130px', left: '200px', color: '$color-active', height: '100px'}}>
      </img>
    </div>
    </Draggable>
    )
  }
}

export default PostIt