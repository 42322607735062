import React from 'react'

import './scss/main.scss'
import { ParticlesContainer } from './components/ParticlesContainer.tsx';
import { AboutMe } from './components/AboutMe.tsx';
import BusinessCard from './components/BusinessCard.tsx';
import ChessBoard from './components/ChessBoard.tsx';
import PostIt from './components/Postit.tsx';

function App() {
  return (
    <body style={{maxHeight:'100vh'}}>
      <ParticlesContainer />
      <PostIt />
      <AboutMe />
      <BusinessCard />
      <ChessBoard />
      
    </body>
  );
}

export default App;
