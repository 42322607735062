import React from 'react'
import Draggable from 'react-draggable'
import about from '../data/about.json'
import details from '../data/details.json'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class BusinessCard extends React.Component {
  render() {
    return(
    <Draggable>
    <div className='card' style={{position: 'absolute', top: '500px', left: '-300px', zIndex: '0', overflow: 'hidden'}}>
    <h1 unselectable="on" className='bcardtext' style={{fontSize: '16px',}}>
    <a href="mailto:rvickers93@gmail.com" >
    rvickers93@gmail.com
    </a>
    </h1>
    <h1 unselectable="on" className='bcardtext' style={{paddingBlockStart: '45px',lineHeight:'0px'}}>
    Riley VICKERS, PhD
    </h1>
    <h1 unselectable="on" className='bcardtext' style={{fontSize: '16px',lineHeight:'15px'}}>
    Data Scientist
    </h1>

    <ul> 
              {details.socialNetworks.map(network => (
                <li style={{display:'inline', color:'black',paddingLeft:'5px',paddingRight:'5px'}} key={network.iconName}>
                  <a href={network.link} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={fab[network.iconName]} />
                  </a>
                </li>
              ))}
            </ul>

    <h1 unselectable="on" className='bcardtext' style={{position: 'absolute', bottom: '-5px', left: '10px'}}>
    <a href={about.cvUrl} target="_blank" rel="noopener noreferrer">Curriculum Vitae</a>
    </h1>

    <h1 unselectable="on" className='bcardtext' style={{position: 'absolute', bottom: '-5px', right: '15px'}}>
    <a href={about.resumeUrl} target="_blank" rel="noopener noreferrer">Résumé</a>
    </h1>

    <h1 className='marker' style={{position: 'absolute', top:'50px', right:'-35px',transform:'rotate(-90deg)', fontSize:'24px'}}>
      Pull me!
    </h1>
    </div>
    </Draggable>
    )
  }
}

export default BusinessCard